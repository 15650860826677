<template>
  <div>
    <pageTitle :title="$t('envelopeusagerecord')" :isBorder="true"></pageTitle>
    <div class="usedContent">
      <div class="mainColor cursor tr backMy" @click="back">
        {{ $t("Returnmyredenvelop") }}
      </div>
    </div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <!-- <el-table-column prop="id" label="ID" width="100"> </el-table-column> -->
        <el-table-column prop="name" :label="$t('setCompanyt')">
          <template slot-scope="scope">
            <span @click="editChange(scope.row, true)">
              {{
                scope.row.company_name_en
                  | priorFormat(scope.row.company_name_zh, LOCALE)
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('envelopeNumber')"
          prop="receive_no"
          width="250"
        >
        </el-table-column>
        <el-table-column :label="$t('RedEnvelopeName')" width="300">
          <template slot-scope="scope">
            {{ scope.row.topic_en | priorFormat(scope.row.topic_zh, LOCALE) }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('Facevalue')" prop="amount">
          <template slot-scope="scope">
            {{ scope.row.discount / 100 || "" }}
          </template>
        </el-table-column>
        <el-table-column :label="$t('handle')">
          <template slot-scope="scope">
            <div class="handle clearfix">
              <div
                class="line fl"
                :class="
                  scope.row.status == 2
                    ? 'back2'
                    : scope.row.status == 3
                    ? 'back3'
                    : 'back3'
                "
              ></div>
              <div class="fl">
                <!-- <span v-if="scope.row.deleted">{{ $t("deleted") }}</span>
                <span v-else> -->
                <span v-if="scope.row.status == 1">{{ $t("unused") }}</span>
                <span v-if="scope.row.status == 2">{{ $t("used") }}</span>
                <span v-if="scope.row.status == 3">{{ $t("expireds") }}</span>
                <!-- </span> -->
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column :label="$t('handleTime')">
          <template slot-scope="scope">
            {{ scope.row.receive_time | secondFormat("LLL") }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        layout="total, prev, pager, next"
        :total="total"
        :page-size="limit"
        :current-page.sync="start"
        @current-change="handlePageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import pageTitle from "~bac/components/common/pageTitle";

export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      tableData: [],
      loading: false,
      tableHeight: 100,
      total: 0,
      limit: 8,
      start: 1,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList(val) {
      this.loading = true;
      try {
        let params = {
          user_id: this.USER_INFO.id,
          not_state: 1,
          start: this.start - 1,
          limit: this.limit,
        };
        let _params = Object.assign(params, val);
        let result = await this.$store.dispatch(
          "baseConsole/myBusinessRedEnvelope",
          _params
        );
        if (result.success) {
          this.tableData = result.data;
          this.total = result.total;
        }
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    handlePageChange(e) {
      this.start = e;
      this.getList();
    },
    back() {
      this.$router.push("/console/conference/myRedEnvelope");
    },
  },
};
</script>
<style lang="less" scoped>
.usedContent {
  padding: 0 12px;
  .backMy {
    padding: 20px;
  }
}
.handle {
  .line {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 8px 5px 0 0;
  }
  .back1 {
    background: #eb242a;
  }
  .back2 {
    background: #52c41a;
  }
  .back3 {
    background: #c3c3c3;
  }
}
</style>